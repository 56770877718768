import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import makeBlockie from "ethereum-blockies-base64";
import { callW3Api } from "../../../../../0.resources/2.js/0.global/3.api/callW3Api";
import { parseDate } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/time";
import ReactMarkdown from 'react-markdown'
import EtherscanLogo from "../../../../../0.resources/3.files/images/etherscan.png";
import { Tooltip } from 'react-tooltip'
import { ethers } from "ethers";
import { getCloudProvider } from "../../../../../0.resources/2.js/0.global/2.contracts/cloudProvider";
import { shortenaddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/global";
import { zeroAddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/prepends";

const CollectionUpper = ({ collection_address, tabSelected, setTabSelected }) => {
    const [details, setDetails] = useState(null);
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [owner, setOwner] = useState(null);

    async function init() {
        let result = await callW3Api("/nft/collection/details", { address: collection_address });
        console.log(result);
        setDetails(result);
    }

    async function getOwner() {
        let contract = new ethers.Contract(collection_address, ["function owner() view returns (address)"], getCloudProvider());
        let result = await contract.owner();
        setOwner(result);
    }

    useEffect(() => {
        init();
        getOwner();
    }, []);

    return (
        <div>
            {details != null ? (
                <div className="w-full h-[200px] bg-dark900 rounded-2xl" style={{ backgroundImage: `url(${details?.media?.banner_image_url ? details?.media?.banner_image_url.split("?")[0] + "?w=1920" : ""})`, backgroundPosition: "center center", backgroundSize: "cover" }}>

                </div>
            ) : (
                <div className="w-full h-[200px] bg-dark900 rounded-2xl animate-pulse">
                </div>
            )}
            <div className="px-20">
                <div className="-mt-16">
                    <div className="">
                        <div className="flex justify-between items-end">
                            <div className="w-32 h-32 rounded-2xl">
                                {details == null ? (
                                    <div className="w-32 h-32 bg-dark900 rounded-2xl animate-pulse" />
                                ) : details?.media?.image_url ? (
                                    <img src={details?.media?.image_url} className="w-32 h-32 rounded-2xl bg-dark950" />
                                ) : (
                                    <img src={makeBlockie(collection_address)} className="w-32 h-32 rounded-2xl" />
                                )}
                            </div>
                            <div className="flex items-center gap-x-4">
                                <Links links={links} details={details} collection_address={collection_address} />
                                {/* {owner?.toLowerCase() != collection_address?.toLowerCase() ? (
                                    <a href={"/nft/" + collection_address + "/edit"}>
                                        <button className="px-6 py-3 rounded-full bg-dark900 hover:bg-dark800 text-white text-md font-semibold">Edit collection</button>
                                    </a>
                                ) : (null)} */}

                            </div>
                        </div>
                        <div className="flex flex-col mt-6">
                            {details == null ? (
                                <div className="w-48 h-10 bg-dark900 rounded-full animate-pulse" />
                            ) : (
                                <div className="text-4xl font-bold text-white">{details?.name}</div>
                            )}

                            <Points points={points} details={details} collection_address={collection_address} owner={owner} />

                            {details != null ? (
                                <div className="">
                                    <div className="flex items-center">
                                        <div className="overflow-x-hidden ellipses w-[700px] flex items-center">
                                            <div className={`text-md text-dark500 -mt-2 ${!descriptionOpen ? "whitespace-nowrap text-ellipsis" : ""}`}>
                                                <ReactMarkdown children={convertLinks(!descriptionOpen ? details?.description?.split(/[\r\n]/, 1)[0] : details?.description)} components={{ a: ({ children }) => <a className="text-blue-500 hover:text-blue-400" href={"https://" + children} target="_blank">{children}</a>, p: ({ children }) => <p className="mt-4">{children}</p> }} />
                                            </div>
                                            {/* <span className="text-md text-dark400 mt-2">{descriptionOpen ? "" : "..."}</span> */}
                                        </div>
                                    </div>
                                    <div className="flex items-center mt-2 gap-x-1 cursor-pointer" onClick={() => setDescriptionOpen(!descriptionOpen)}>
                                        <p className="text-blue-500 text-sm hover:text-blue-400">{!descriptionOpen ? "View more" : "View less"}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-[700px] h-8 bg-dark900 rounded-full animate-pulse" />
                            )}

                        </div>
                    </div>

                    <div className="">
                        <Stats collection_address={collection_address} details={details} />
                    </div>
                </div>



                <div className="flex items-center gap-x-2 w-fit mt-8">
                    <div className={`flex items-center gap-x-2 px-6 py-3 rounded-full cursor-pointer ${tabSelected == "items" ? "bg-dark900 text-white" : "text-dark500 hover:text-white"}`} onClick={() => setTabSelected("items")}>
                        <FontAwesomeIcon icon={['fas', 'box']} className={`text-md`} />
                        <p className={`text-md font-semibold`}>Items</p>
                    </div>
                    <div className={`flex items-center gap-x-2 px-6 py-3 rounded-full cursor-pointer ${tabSelected == "activity" ? "bg-dark900 text-white" : "text-dark500 hover:text-white"}`} onClick={() => setTabSelected("activity")}>
                        <FontAwesomeIcon icon={['fas', 'chart-line']} className={`text-md`} />
                        <p className={`text-md font-semibold`}>Activity</p>
                    </div>
                </div>


            </div>
        </div >
    );

}

export default CollectionUpper;

const points = ["Created", "Chain", "Schema", "Owner"]

const Points = ({ points, details, collection_address, owner }) => {

    return (
        <div className="flex items-center py-4 gap-x-4">
            {points?.map((point, index) => (
                <div key={index}>
                    {details != null ? (
                        <div className="flex items-center gap-x-4 ">
                            <div className="flex items-center gap-x-2">
                                <p className="text-md font-normal text-dark500">{point}</p>
                                {point != "Owner" ? (
                                    <p className="text-md font-semibold text-white">
                                        {point == "Created" ? (
                                            details?.created_date ? parseDate(new Date(details?.created_date)) : "-"
                                        ) : point == "Chain" ? (
                                            details?.contract?.chain ? details?.contract?.chain?.charAt(0).toUpperCase() + details?.contract?.chain.slice(1) : "-"
                                        ) : point == "Schema" ? (
                                            details?.contract?.schema ? details?.contract?.schema : "-"
                                        ) : ""}
                                    </p>
                                ) : (
                                    <a href={"/address/" + (owner ? owner : zeroAddress)} className="flex justify-center items-center gap-x-2 w-fit bg-dark900 rounded-full px-4 py-2 hover:bg-dark800 cursor-pointer">
                                        <img src={makeBlockie(owner ? owner : zeroAddress)} className="w-6 h-6 rounded-full" />
                                        <p className="text-white text-md font-semibold">{owner ? shortenaddress(owner) : shortenaddress(zeroAddress)}</p>
                                    </a>
                                )}


                            </div>

                            {index == points?.length - 1 ? "" : (
                                <p className="text-sm font-semibold text-gray-500 dark:text-dark500">•</p>
                            )}
                        </div>
                    ) : (
                        <div className="w-14 h-4 bg-dark900 rounded-full animate-pulse" />
                    )}
                </div>
            ))}
        </div>
    )
}

const Stats = ({ collection_address, details }) => {
    const [stats, setStats] = useState(null);

    async function init() {
        let result = await callW3Api("/nft/collection/stats", { slug: "boredapeyachtclub" });
        console.log(result);
        setStats(result);
    }

    useEffect(() => {
        // if (details?.collection?.slug) {
        init();
        // }
    }, [details]);

    let points = ["items", "owners", "floor price", "total volume"]

    return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-6 mt-8">
            {points.map((point, index) => (
                <div key={index}>
                    {stats != null ? (
                        <div className="flex flex-col items-start">
                            <p className="text-2xl font-semibold">{
                                point == "items" ? (
                                    stats?.total_supply ? parseInt(stats?.total_supply)?.toLocaleString() : "-"
                                ) : point == "owners" ? (
                                    stats?.num_owners != 0 && stats?.num_owners ? stats?.num_owners?.toLocaleString() : "-"
                                ) : point == "floor price" ? (
                                    stats?.floor_price != 0 && stats?.floor_price ? stats?.floor_price?.toLocaleString() + " ETH" : "-"
                                ) : point == "total volume" ? (
                                    stats?.total_volume != 0 && stats?.total_volume ? parseInt(stats?.total_volume)?.toLocaleString() + " ETH" : "-"
                                ) : ""
                            }</p>
                            <p className="text-md font-normal mt-2 text-gray-500 dark:text-dark500">{point}</p>
                        </div>
                    ) : (
                        <div className="w-20 h-4 bg-dark900 rounded-full animate-pulse" />
                    )}
                </div>
            ))}
        </div>
    )
}


//write a function to convert links in text that aren't markdown to markdown links
function convertLinks(text) {
    if (text == null) {
        return "";
    }

    let newText = text;

    let words = text.split(" ");
    words.forEach((word) => {
        if (word.includes("http") && !word.includes("[")) {
            newText = newText.replace(word, `[${word}](${word})`);
        } else if (word.includes("www") && !word.includes("[")) {
            newText = newText.replace(word, `[${word}](https://${word})`);
        }
    });

    return newText;
}

let css = {
    icon: "text-white text-xl"
}

let links = [
    {
        name: "etherscan",
        icon: <img src={EtherscanLogo} className="w-5 h-5" />,
    },
    {
        name: "website",
        icon: <FontAwesomeIcon icon={['fas', 'globe']} className={css.icon} />,
    },
    {
        name: "twitter",
        icon: <FontAwesomeIcon icon={['fab', 'twitter']} className={css.icon} />,
    },
    {
        name: "instagram",
        icon: <FontAwesomeIcon icon={['fab', 'instagram']} className={"text-white text-2xl"} />,
    },
    {
        name: "discord",
        icon: <FontAwesomeIcon icon={['fab', 'discord']} className={css.icon} />,
    },
    {
        name: "telegram",
        icon: <FontAwesomeIcon icon={['fab', 'telegram']} className={css.icon} />,
    }
]

const Links = ({ links, details, collection_address }) => {

    return (
        <div className="flex items-center gap-x-2">
            {links.map((link, index) => (
                details != null ? (
                    link.name == "etherscan" ? (
                        <div key={index}>
                            <a href={"https://etherscan.io/collection_address/" + collection_address} target="_blank" className="w-12 h-12 flex items-center justify-center cursor-pointer" data-tooltip-id={"tooltip-" + link.name} data-tooltip-content={link.name[0].toUpperCase() + link.name.substring(0 + 1)}>
                                {link.icon}
                            </a>
                            <Tooltip id={"tooltip-" + link.name} />
                        </div>
                    ) :
                        details?.links?.[link.name] != null ? (
                            <div key={index}>
                                <a href={
                                    link.name == "website" ? (
                                        details?.links?.[link.name]
                                    ) : link.name == "twitter" ? (
                                        details?.links?.[link.name].includes("https://") ? details?.links?.[link.name] : "https://twitter.com/" + details?.links?.[link.name]
                                    ) : link.name == "instagram" ? (
                                        details?.links?.[link.name].includes("https://") ? details?.links?.[link.name] : "https://instagram.com/" + details?.links?.[link.name]
                                    ) : link.name == "discord" ? (
                                        details?.links?.[link.name].includes("https://") ? details?.links?.[link.name] : "https://discord.gg/" + details?.links?.[link.name]
                                    ) : link.name == "telegram" ? (
                                        details?.links?.[link.name].includes("https://") ? details?.links?.[link.name] : "https://t.me/" + details?.links?.[link.name]
                                    ) : ""
                                } target="_blank" className="w-12 h-12 flex items-center justify-center cursor-pointer" data-tooltip-id={"tooltip-" + link.name} data-tooltip-content={link.name[0].toUpperCase() + link.name.substring(0 + 1)}>
                                    {link.icon}
                                </a>
                                <Tooltip id={"tooltip-" + link.name} />
                            </div>
                        ) : (null)
                ) : (null)
            ))}
        </div>
    )
}