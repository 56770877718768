import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import RoadmapBg from "../../0.resources/3.files/images/roadmap.webp";

const Roadmap = ({ }) => {

    useEffect(() => {
        document.getElementById("title").innerHTML = "Roadmap - Sphere";
    }, []);

    let boxes1 = [
        {
            title: "Profiles (V1)",
            description: "Introducing the foundational layer of Sphere: user profiles. The initial rollout offers capabilities like profile creation, content posting, commenting, and engagement metrics.",
            status: "development"
        },
        {
            title: "Posts",
            description: "Initiating content creation with the introduction of posts. This feature supports text, imagery, and video content, equipped with engagement tools such as likes, comments, and cross-platform sharing.",
            status: "development"
        },
        {
            title: "Profiles (V2)",
            description: "Enhancing user profiles by integrating follow functionalities and group creation. Furthermore, we're incorporating visibility for user-owned NFTs and tokens.",
            status: "development"
        },
        {
            title: "NFT Integration",
            description: "Onboard Non-Fungible Tokens (NFTs) into Sphere. Users will seamlessly view Ethereum-based NFTs, which can be set as profile avatars and showcased on profiles.",
            status: "development"
        },
        {
            title: "NFT Marketplace",
            description: "Launching the Sphere NFT marketplace, an arena where users can transact—buy, sell, or even mint their own unique digital assets.",
            status: "development"
        },
        {
            title: "Token Visibility",
            description: "Elevating the user's financial dashboard by reflecting Ethereum-based tokens on their profiles.",
            status: "development"
        },
        {
            title: "Token Marketplace",
            description: "Sphere's digital token trading hub debuts, where users can transact tokens, or mint and list their unique digital currencies.",
            status: "development"
        },
        {
            title: "Digital Wallet",
            description: "Incorporating a wallet feature, empowering users with robust management tools for their digital assets, inclusive of transfers, receipt, and token swaps.",
            status: "development"
        },
        {
            title: "Token Staking",
            description: "Introduce staking mechanisms, allowing users to securely stake their tokens within Sphere, while earning potential rewards."
        },
        {
            title: "NFT Marketplace (V2)",
            description: "Advancing our NFT marketplace to enable on-platform NFT minting. Users can conceptualize, create, and list their digital assets."
        },
        {
            title: "Token Marketplace (V2)",
            description: "Elevate the token marketplace, offering a full suite of token minting capabilities directly within Sphere."
        },
        {
            title: "Messaging",
            description: "Introducing a messaging feature, allowing users to communicate with each other directly within Sphere."
        },
        {
            title: "Communities",
            description: "Launching community-centric spaces for likeminded users. Communities can also earn official endorsements, particularly for NFT collections."
        },
        {
            title: "Play-to-Earn Games",
            description: "Integrating blockchain-based gaming, where users can engage either recreationally or with the prospect of cryptocurrency rewards."
        },
        {
            title: "Sphere Mobile Experience",
            description: "Unveiling Sphere's mobile interface, optimizing user engagement and interactions across varied devices."
        },
        {
            title: "Sphere Engagement Rewards",
            description: "Introducing a structured rewards paradigm, wherein users ascend through tiers and gain access to exclusive perks, dictated by their Sphere activity and overall contribution."
        }
    ]

    let boxes2 = [
        {
            title: "Sphere Token Launch",
            description: "Introducing the Sphere token as the primary cryptocurrency of the Sphere platform. Users can earn, trade, and use these tokens within Sphere."
        },
        {
            title: "Sphere Token Integration",
            description: "All activities on Sphere, from content creation to social engagements, will have an associated tokenomics model, allowing users to earn and spend Sphere tokens."
        },
        {
            title: "Sphere Community Forums",
            description: "Creation of dedicated forums where members can discuss, share ideas, and get updates about the Sphere platform, its features, and future directions."
        },
        {
            title: "Sphere Ambassador Program",
            description: "Introducing an ambassador program to incentivize users to promote and expand the Sphere community, rewarding them with Sphere tokens."
        },
        {
            title: "Advanced Analytics",
            description: "Provide users with advanced insights into their content performance, token earnings, and other metrics to better understand and grow their presence on Sphere."
        },
        {
            title: "Cross-Chain Integration",
            description: "Expand the token and NFT functionalities to support multiple blockchains, broadening the usability and appeal of Sphere."
        },
        {
            title: "Advertising Platform",
            description: "Launch an advertising platform where users can promote their content, products, and services to a targeted audience across Sphere."
        },
        {
            title: "Sphere Partnerships",
            description: "Collaborate with other platforms, creators, and crypto projects, bringing exclusive content, features, and integrations to Sphere."
        },
        {
            title: "Improved Security Features",
            description: "Strengthening the platform's security by introducing multi-factor authentication, encrypted communications, and periodic security audits."
        }

    ]

    let boxes3 = [
        {
            title: "Decentralized Governance Launch",
            description: "Initiate a decentralized governance system, allowing users to directly participate in decision-making processes for Sphere's future."
        },
        {
            title: "Sphere Token Distribution",
            description: "Distribution of tokens to active users and creators, enabling them to participate in voting and proposal submissions."
        },
        {
            title: "Proposal System",
            description: "Users can submit proposals for new features, integrations, or any changes they want to see on Sphere. Proposals with enough support are then voted upon by the community."
        },
        {
            title: "Delegate System",
            description: "Allow users to delegate their voting power to trusted community members, ensuring continuous participation in governance even if they can't be actively involved."
        },
        {
            title: "Ecosystem Development Grants",
            description: "Allocate funds and resources to developers and content creators, promoting the development of new tools, features, and content that enrich the Sphere ecosystem."
        },
        {
            title: "Sphere DAO (Decentralized Autonomous Organization)",
            description: "Establishment of the Sphere DAO, giving the community a structured system to make decisions, allocate resources, and oversee developments."
        },
        {
            title: "Community-led Events",
            description: "Encourage and support users in organizing virtual and physical events, like meetups, workshops, and conferences, promoting Sphere and its values."
        },
        {
            title: "Inter-DAO Collaboration",
            description: "Collaborate with other DAOs and decentralized projects for mutual benefits, be it in terms of technology, content, or community engagements."
        },
        {
            title: "Decentralized Dispute Resolution",
            description: "Implement a system for resolving disputes in a decentralized manner, involving community arbitrators and ensuring fairness."
        },
        {
            title: "On-chain Revenue Distribution",
            description: "Incorporate smart contracts to automatically distribute revenue (from ads, partnerships, etc.) to token holders or reinvest in the platform based on governance decisions."
        }
    ]

    let boxes4 = [
        {
            title: "Global Expansion",
            description: "Localize Sphere for different regions, offering multiple language support, and regional community engagement initiatives to cater to a global audience."
        },
        {
            title: "Mainstream Collaborations",
            description: "Partner with mainstream media, influencers, and other major platforms to bring exclusive content and features to Sphere, reaching a broader audience."
        },
        {
            title: "Advanced Scalability Solutions",
            description: "Implement layer-2 scaling solutions or explore other blockchains to ensure fast, cost-effective, and seamless user interactions even with massive user growth."
        },
        {
            title: "Sustainable Development",
            description: "Introduce features and partnerships focusing on sustainability and eco-friendly initiatives, emphasizing Sphere's commitment to a green future."
        },
        {
            title: "Offline Sphere Events",
            description: "Host global Sphere conferences, meetups, and workshops, strengthening the community bond and promoting real-world networking."
        },
        {
            title: "Charitable Initiatives",
            description: "Introduce features where users can donate tokens or NFTs to charitable causes, emphasizing Sphere's commitment to social good."
        }
    ]

    let phases = [
        {
            title: "Phase 1",
            name: "Sphere Foundations",
            description: "Initiating the Sphere journey, this phase unveils the platform's primary functionalities. It is dedicated to establishing a solid infrastructure for Sphere and intensively driving user engagement.",
            plans: boxes1
        },
        {
            title: "Phase 2",
            name: "Sphere Genesis",
            description: "This phase heralds the introduction of the Sphere token, poised to underpin all transactions and economic activities on the platform. Emphasis during this phase will be on fortifying community bonds and fostering the holistic growth of the Sphere ecosystem.",
            plans: boxes2
        },
        {
            title: "Phase 3",
            name: "Sphere Expansion",
            description: "In this phase, Sphere transitions into a more decentralized model, unveiling governance tools and mechanisms. It facilitates users and creators in accessing and claiming Sphere tokens, a testament to their contributions and interactions within the platform.",
            plans: boxes3
        },
        {
            title: "Phase 4",
            name: "Sphere Evolution",
            description: "This anticipatory phase is geared towards broadening Sphere's horizons, with specific plans and objectives to be determined based on the evolving needs and feedback from the community.",
            plans: boxes4
        },
    ]

    return (
        <div className="w-full h-full">
            <div className="w-screen h-screen flex justify-center items-center absolute top-0 left-0" style={{ backgroundImage: `url(${RoadmapBg})`, backgroundPosition: "center center", backgroundSize: "cover" }}>
                <p className="text-4xl md:text-6xl font-semibold text-white text-center mb-20 tracking-widest">ROADMAP</p>
            </div>

            <div className="min-h-screen" style={{ marginTop: window.innerHeight + "px" }}>

                <div className="flex justify-end items-center md:pt-5" id="map">
                    <div className="w-12/12">
                        {phases.map((phase, index) => (
                            <div key={index} className="w-full bg-dark950 py-10 md:mt-10 md:flex justify-between gap-x-10">
                                <div className="w-full md:w-4/12">
                                    <div className="md:sticky md:top-20">
                                        <p className="text-lg font-semibold text-white">{phase.title}</p>
                                        <p className="text-4xl font-semibold text-white mt-2">{phase.name}</p>
                                        <p className="text-md font-normal text-dark500 mt-5">{phase.description}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center w-full md:w-8/12 pt-10 md:pt-0">
                                    <div className="w-full grid lg:grid-cols-2 gap-x-10 gap-y-10">
                                        {phase.plans.map((plan, index) => (
                                            <div key={index} className="bg-dark900 px-10 py-10 rounded-2xl">
                                                <div className="mt-0">
                                                    {plan.status == null ? (
                                                        <div className="flex items-center justify-center bg-blueBg rounded-full w-fit px-4 py-2">
                                                            <FontAwesomeIcon icon="clock" className="text-blue-500" />
                                                            <p className="text-blue-500 text-xs font-semibold ml-2">Coming Soon</p>
                                                        </div>

                                                    ) : plan.status == "development" ? (
                                                        <div className="flex items-center justify-center bg-yellowBg rounded-full w-fit px-4 py-2">
                                                            <FontAwesomeIcon icon="code" className="text-yellow-500 text-xs" />
                                                            <p className="text-yellow-500 text-xs font-semibold ml-2">Development</p>
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center justify-center bg-greenBg rounded-full w-fit px-4 py-2">
                                                            <FontAwesomeIcon icon="check-circle" className="text-green-500 text-xs" />
                                                            <p className="text-green-500 text-xs font-semibold ml-2">Live</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <p className="text-xl font-semibold text-white mt-4">{plan.title}</p>
                                                <p className="text-md font-normal text-dark500 mt-2">{plan.description}</p>


                                            </div>
                                        ))}
                                    </div>
                                </div>


                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <div className="w-full flex justify-center items-center pt-10 pb-10">
                <a href="#map" className="text-blue-500 flex items-center gap-x-2 cursor-pointer text-lg">
                    Go to top
                    <FontAwesomeIcon icon="arrow-up" className="text-md" />
                </a>
            </div>
        </div>
    );

}

export default Roadmap;
