import React, { useState, useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./2.views/1.login/login";
import { useAccount } from "wagmi";
import Header from "./2.views/0.global/1.header/header";
import Feed from "./2.views/3.feed/feed";
import Profile from "./2.views/2.profile/profile";
import Roadmap from "./2.views/7.roadmap/roadmap";
import Sidebar from "./4.sidebar/sidebar";
import Tokens from "./2.views/4.tokens/tokens";
import TokenPage from "./2.views/4.tokens/components/token/token";
import NFTs from "./2.views/5.nfts/nfts";
import Collection from "./2.views/5.nfts/components/collection/collection";
import Token from "./2.views/5.nfts/components/token/token";
import ComingSoon from "./2.views/1.login/comingsoon";
import About from "./2.views/7.roadmap/about";

const Main = () => {
  console.log(window.location.pathname.split("/")[1])
  return (
    <div className="w-screen min-h-screen flex flex-col">

      {/* Header */}
      <div className={`w-full flex justify-center bg-dark950 ${window.location.pathname.split("/")[1] == "roadmap" ? "" : "sticky top-0"}`} id="header" style={{ zIndex: 1000000000 }}>
        <div className="w-full 2xl:w-[1280px] px-5 md:px-10 2xl:px-0 flex justify-center">
          <Header />
        </div>
      </div>

      {/* Main Content */}
      <div className="w-full flex-1 flex justify-center" id="mainpart">
        <div className="w-full 2xl:w-[1280px] px-5 md:px-10 2xl:px-0 flex justify-center">
          <div className="my-0 flex flex-1 pb-4 w-full" id="main">
            <ComingSoonRoutes />
          </div>
        </div>
      </div>
    </div>


  );

}

export default Main;

const ComingSoonRoutes = () => {
  let { address } = useAccount();

  return (
    <Routes>
      <Route path="/" element={<About />} />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<ComingSoon />} />
    </Routes>
  )
}

const MainRoutes = () => {
  let { address } = useAccount();

  return (
    <Routes>
      <Route path="/" element={<Feed />} />
      <Route path="/profile" element={address == null ? <Login /> : <Profile />} />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/about" element={<About />} />
      <Route path="/tokens" element={<Tokens />} />
      <Route path="/token/:address" element={<TokenPage />} />
      <Route path="/nfts" element={<NFTs />} />
      <Route path="/nft/:collection_address" element={<Collection />} />
      <Route path="/nft/:collection_address/:tokenid" element={<Token />} />
      <Route path="/address/:account_address" element={<Profile />} />
    </Routes>
  )
}


