import "./polyfills.js";
import React, { useState, useEffect, useContext } from "react";
import "./0.resources/2.js/0.global/0.smallfunctions/imports_css";
import Main from "./main";
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, darkTheme, lightTheme, Theme, connectorsForWallets } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { AuthProvider } from "./1.components/1.wrappers/auth.js";
import { GlobalProvider } from "./1.components/1.wrappers/darkMode.js";
import { GlobalParams } from "./1.components/1.wrappers/darkMode.js";
import { colors } from "./0.resources/1.css/colors.js";
import merge from 'lodash.merge';
import { LoginProvider } from "./1.components/1.wrappers/login.js";
import { injectedWallet, rainbowWallet, metaMaskWallet, coinbaseWallet, walletConnectWallet, trustWallet, ledgerWallet } from '@rainbow-me/rainbowkit/wallets';

const App = ({ }) => {
  const { chains, provider } = configureChains(
    [mainnet],
    [jsonRpcProvider({
      rpc: (chain) => ({
        http: "https://nd-844-046-177.p2pify.com/5a763b2a0277ff74aa59bad0f0d9a950",
      }),
    })]
  );

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        injectedWallet({ chains }),
        metaMaskWallet({ chains }),
        coinbaseWallet({ chains, appName: 'Sphere' }),

        walletConnectWallet({ chains }),
      ],
    },
    {
      groupName: 'Others',
      wallets: [
        trustWallet({ chains }),
        ledgerWallet({ chains }),
        rainbowWallet({ chains }),
      ],
    },
  ]);

  const wagmiClient = createClient({
    autoConnect: localStorage.getItem("walletConnected") == null ? false : true,
    connectors,
    provider
  })

  return (
    <WagmiConfig client={wagmiClient}>
      <AuthProvider>
        <GlobalProvider>
          <LoginProvider>
            <MainApp chains={chains} />
          </LoginProvider>
        </GlobalProvider>
      </AuthProvider>
    </WagmiConfig >

  );

}

export default App;

const myDarkTheme = merge(darkTheme({
  fontStack: "system",
  borderRadius: "large"
}), {
  colors: {
    accentColor: colors.main,
    modalBackground: "rgb(24 24 27)",
  }
});

const MainApp = ({ chains }) => {
  const { darkMode } = GlobalParams();

  return (
    <div className={"dark"}>
      <RainbowKitProvider chains={chains} theme={darkMode ? myDarkTheme : lightTheme({
        fontStack: "system",
        borderRadius: "large"
      })} >
        <div className="">
          <Main />
        </div>
      </RainbowKitProvider >
    </div >
  )
}

