import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import Chart from "./chart";
import { callW3Api } from "../../../../../0.resources/2.js/0.global/3.api/callW3Api";
import { calculateZeroes, formatinusd } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import makeBlockie from "ethereum-blockies-base64";
import { shortenaddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/global";

const TokenMain = ({ address, details }) => {
    const [price, setPrice] = useState(null);

    async function init() {
        let result = await callW3Api(`token/price`, { address: address });

        setPrice(result);
    }

    useEffect(() => {
        init();
    }, []);
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-x-4">
                    {details?.logo ? (
                        <img src={details?.logo} className="w-14 h-14 rounded-lg" />
                    ) : (
                        <img src={makeBlockie(address)} className="w-14 h-14 rounded-lg" />
                    )}
                    <div>
                        <p className="text-xl font-bold">{details?.symbol ? details?.symbol : shortenaddress(address)}</p>
                        <p className="text-lg font-semibold text-dark500">{details?.name ? details?.name : shortenaddress(address)}</p>
                    </div>
                </div>
                <div className="mt-2">
                    <p className="text-3xl font-bold text-end">{price?.usdPrice ? "$" + calculateZeroes(price?.usdPrice) : "-"}</p>
                    <p className={`text-lg font-semibold text-end ${price ? parseFloat(price["24hrPercentChange"]) > 0 ? "text-green-500" : "text-red-500" : "text-dark500"}`}>{price ? (parseFloat(price["24hrPercentChange"])).toFixed(2) + "%" : "-"}</p>
                </div>
            </div>

            {/* <div className="mt-8">
                <Stats details={details} />
            </div> */}

            <div className="w-full h-[500px] mt-8">
                <Chart />
            </div>

        </div>
    );

}

export default TokenMain;


