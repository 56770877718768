import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount, useDisconnect } from "wagmi";
import { AiFillHome, AiOutlineHome, AiFillShopping, AiOutlineShopping, AiFillWallet, AiOutlineWallet } from 'react-icons/ai'
import { FaRegUserCircle, FaUserCircle, FaGear } from 'react-icons/fa'
import { ConnectWallet } from "../../../1.components/2.wallet/connectWallet";
import { BiCoinStack, BiImage, BiPencil, BiLineChart } from "react-icons/bi";
import { zeroAddress } from "../../../0.resources/2.js/0.global/0.smallfunctions/prepends";
import makeBlockie from "ethereum-blockies-base64";
import { FaCoins, FaImage, FaUser, FaMap } from "react-icons/fa";
import Logo from "../../../0.resources/3.files/logo/logo_trans2.webp";
import { useRef } from "react";
import { HiMenuAlt2, HiOutlineMenuAlt2 } from "react-icons/hi";

const Header = ({ }) => {
    let { address } = useAccount();

    let links = [
        {
            name: "Home",
            link: "/",
            iconSelected: <FontAwesomeIcon icon={["fas", "fa-home"]} className="text-xl" />,
        },
        {
            name: "Tokens",
            link: "/tokens",
            iconSelected: <FaCoins className="text-xl" />,
        },
        {
            name: "NFTs",
            link: "/nfts",
            iconSelected: <FaImage className="text-xl" />,
        },

        {
            name: "Roadmap",
            link: "/roadmap",
            iconSelected: <FaMap className="text-xl" />,
        },
        {
            name: "About",
            link: "/about",
            iconSelected: <HiMenuAlt2 className="text-xl" />,
        },
    ]

    return (
        <div className="w-full">
            <div className="py-3 flex items-center justify-between gap-x-4 w-full">
                <div className="flex flex-none">
                    <a href="/" className="">
                        <img src={Logo} className="w-9 h-9" />
                    </a>
                </div>
                <div className="hidden lg:block">
                    <HeaderLinks items={links} />
                </div>
                <div className="flex items-center gap-x-4 w-full">
                    <div className="px-6 py-3 bg-dark900 rounded-full flex items-center justify-between gap-x-2 w-full">
                        <div className="flex items-center gap-x-3">
                            <FontAwesomeIcon icon={['fas', 'search']} className="text-md text-dark500" />
                            <p className="hidden md:block text-md font-semibold text-dark500 pr-2">Search</p>
                            <p className="block md:hidden text-md font-semibold text-dark500 pr-2">Search</p>
                        </div>
                        <FontAwesomeIcon icon={['fas', 'chevron-right']} className="text-md text-dark500 text-sm" />
                    </div>
                    <div className="">
                        <ProfileButton address={address} />
                    </div>
                </div>
            </div>
            <div className="w-full flex items-center justify-start md:justify-center flex lg:hidden overflow-x-scroll pb-2 noscrollbars">
                <div className="">
                    <HeaderLinks items={links} />
                </div>
            </div>
        </div>
    );
}

export default Header;


const ProfileButton = ({ address }) => {
    const [divVisible, setDivVisible] = useState(false);
    const { disconnect } = useDisconnect()

    let css = {
        icon: "text-xl"
    }
    let links = [
        {
            name: "Profile",
            link: "/profile",
            icon: <FaUserCircle className={css.icon} />,
        },
        {
            name: "Settings",
            link: "/profile/settings",
            icon: <FontAwesomeIcon icon={["fas", "fa-gear"]} className={css.icon} />,
        },
        {
            name: "Logout",
            link: () => disconnectWallet(),
            icon: <FontAwesomeIcon icon={["fas", "fa-right-from-bracket"]} className={css.icon} />,
        }
    ]

    async function disconnectWallet() {
        localStorage.clear();
        sessionStorage.clear();
        disconnect();
        window.location = "/";
    }

    return (
        <div className="flex items-center px-4 rounded-full bg-dark900 py-2">
            <div className="pr-4">
                <a href="/wallet" className={`flex items-center gap-x-2 ${window.location.pathname.split("/")[1] == "wallet" ? "text-white" : "text-dark500 hover:text-white"}`}>
                    <AiFillWallet className="text-2xl" />
                    <p className="text-md font-semibold hidden md:block">Wallet</p>
                </a>
            </div>
            <div className="pl-4 border-l-2 border-dark800" onMouseOver={() => address == null ? null : setDivVisible(true)} onMouseOut={() => address == null ? null : setDivVisible(false)}>
                <div className="w-8 h-8">
                    <a href="/profile" className="">
                        <img src={makeBlockie(address != null ? address : zeroAddress)} className='w-8 h-8 rounded-full' />
                        <ConnectWallet type={"none"} />
                    </a>
                    <div className="absolute pt-4 w-[250px] -ml-[200px]" style={{ display: divVisible ? "block" : "none" }}>
                        <div className="bg-dark800 rounded-lg">
                            {links.map((item, index) => (
                                <a href={typeof item.link === 'string' ? item.link : null} onClick={() => typeof item.link === 'string' ? null : item.link()} key={index} className={`flex items-center gap-x-4 px-6 py-3 hover:bg-dark700 ${index != links.length - 1 ? index == 0 ? "border-b border-dark700 rounded-t-lg" : "border-b border-dark700" : "rounded-b-lg"}`}>
                                    {item.icon}
                                    <p className="text-md font-semibold">{item.name}</p>
                                </a>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const HeaderLinks = ({ items }) => {

    console.log(window.location.pathname.split("/")[1])

    return (
        <div className="flex gap-x-0">
            {items.map((item, index) => {
                return (
                    <a href={item.link} key={index}>
                        <div className={`flex items-center gap-x-3 px-6 py-3 rounded-full ${window.location.pathname.split("/")[1] == "" ? item.name.toLowerCase() == "home" ? "bg-dark900 text-white" : "text-dark500 hover:text-white" : window.location.pathname.split("/")[1] == item.name.toLowerCase() ? "bg-dark900 text-white" : "text-dark500 hover:text-white"}`}>
                            <div className="flex items-center justify-center">
                                {item.iconSelected}
                            </div>
                            <p className="font-semibold text-md">{item.name}</p>
                        </div>
                    </a>
                )
            }
            )}
        </div>
    )
} 