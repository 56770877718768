import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import Logo from "../../0.resources/3.files/logo/logo_trans2.webp";
import CoinHero from "../../0.resources/3.files/images/coin_stage3.webp";
import BackgroundImg from "../../0.resources/3.files/images/gradient_bg5.webp";
import BackgroundImg2 from "../../0.resources/3.files/images/bg1.png";
import Grid from "../../0.resources/3.files/images/grid.webp";

const About = ({ }) => {

    useEffect(() => {
        document.getElementById("hero").style.marginTop = (document.getElementById("header").offsetHeight) + "px";
        document.getElementById("main-div").style.marginTop = (window.innerHeight * 0.5) + (document.getElementById("header").offsetHeight) + "px";
    }, []);

    useEffect(() => {
        // This code will run after the component has rendered
        const myElement = document.getElementById('header');
        // if (myElement) {
        console.log(myElement.clientHeight);
        // }
    }, []);


    let css = {
        icon: "text-5xl"
    }

    let arenas = [{
        name: "Social Media",
        icon: <FontAwesomeIcon icon={['fas', 'fa-at']} className={css.icon} />,
    },
    {
        name: "Wallet",
        icon: <FontAwesomeIcon icon={['fas', 'fa-wallet']} className={css.icon} />,
    },
    {
        name: "NFTs",
        icon: <FontAwesomeIcon icon={['fas', 'fa-image']} className={css.icon} />,
    },
    {
        name: "DeFi",
        icon: <FontAwesomeIcon icon={['fas', 'fa-coins']} className={css.icon} />,
    },
    {
        name: "Gaming",
        icon: <FontAwesomeIcon icon={['fas', 'fa-gamepad']} className={css.icon} />,
    },
    {
        name: "Marketplace",
        icon: <FontAwesomeIcon icon={['fas', 'fa-store']} className={css.icon} />,
    },
    ]

    let colors = ["#B82E76", "#803CD9", "#0052D5", "#e0bf3d", "#4CA352", "#00A4D9"]


    return (
        <div className="w-full h-full mb-10">
            <div className="w-full flex justify-center items-center py-20 bg-black absolute top-0 left-0" id="hero" style={{ height: window.innerHeight * 0.5, backgroundImage: `url(${BackgroundImg})`, backgroundPosition: "center center", backgroundSize: "cover" }}>
                {/* <div className="w-full md:w-6/12 h-full flex items-center justify-start md:justify-center px-10">


                </div>
                <div className="w-full md:w-6/12 px-10 mt-10 md:mt-0">
                    <div className="text-white ">
                        <p className="text-6xl font-bold mt-2 uppercase">Say</p>
                        <p className="text-6xl font-bold  mt-2 uppercase">hello</p>
                        <p className="text-6xl font-bold  mt-2 uppercase">to</p>
                        <p className="text-6xl font-bold  mt-2 uppercase">Sphere!</p>
                        <p className="text-2xl font-normal  mt-6 italic">The "everything" platform for everyone</p>
                    </div>
                </div> */}
                <img src={Logo} className="w-40 md:h-40" />

            </div>

            <div className="pt-0" id="main-div" style={{ marginTop: window.innerHeight * 0.5 }}>
                <div className="w-full h-full">
                    <div className="">
                        <p className="text-5xl md:text-5xl font-semibold text-white text-center uppercase">KEY Features</p>
                        <p className="text-2xl md:text-2xl font-normal text-dark500 mt-4 text-center">Sphere is a multi-arena platform that brings together the best of Web3</p>
                    </div>
                    <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10">
                        {arenas.map((arena, index) => (
                            <div className="w-full aspect-square bg-dark900 flex items-center gap-x-4 p-10 md:p-14" key={index}>
                                <div>
                                    {arena.icon}
                                    <div className="mt-10">
                                        <p className="text-4xl font-semibold text-white uppercase">{arena.name}</p>
                                        <p className="text-lg font-normal text-white mt-2">on Sphere</p>

                                        <div className="flex items-center justify-center bg-blueBg rounded-full w-fit px-4 py-2 mt-8">
                                            <FontAwesomeIcon icon="clock" className="text-blue-500" />
                                            <p className="text-blue-500 text-xs font-semibold ml-2">Coming Soon</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <div className="mt-20 ">
                <div className="w-full h-full flex justify-center items-center gap-x-10" style={{ backgroundImage: `url(${Grid}), url(${BackgroundImg})`, backgroundPosition: "center center", backgroundSize: "cover" }} >
                    <div className="w-6/12 flex justify-center items-center">
                        <img src={CoinHero} className="w-[500px]" />
                    </div>

                </div>

                <div className="w-full h-full lg:flex justify-between items-start gap-x-10 bg-dark900">
                    <div className="w-full lg:w-6/12 px-5  md:px-16 pt-16 pb-10 lg:pb-0 lg:py-16 lg:mb-16">
                        <p className="text-5xl md:text-5xl font-semibold text-white">REWARDS PROTOCOL</p>
                        <p className="text-2xl md:text-2xl font-normal text-white mt-4">The Sphere (SPHERE) token is the backbone of Sphere</p>
                        {/* <div className="text-xl font-normal text-blue-500 mt-8 flex gap-x-2 items-center">
                            Learn more about how you can earn SPHERE
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-blue-500 text-sm" />
                        </div> */}
                        <div className="flex items-center justify-center bg-blueBg rounded-full w-fit px-4 py-2 mt-8">
                            <FontAwesomeIcon icon="clock" className="text-blue-500" />
                            <p className="text-blue-500 text-xs font-semibold ml-2">Coming Soon</p>
                        </div>

                    </div>
                    <div className="w-full lg:w-6/12 px-5 md:px-16 py-0 lg:py-16 pb-10">
                        <Rewards />
                    </div>
                </div>
            </div>

            <div className="mt-20 bg-dark900 py-20 lg:py-28 flex flex-col items-center">
                <p className="text-4xl md:text-5xl font-semibold text-white text-center">ROADMAP</p>
                <a href="/roadmap" className="flex items-center gap-x-2 text-blue-500 mt-6 text-xl">
                    View Roadmap
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-blue-500 text-sm" />
                </a>
            </div>
        </div>
    );

}

export default About;


const Rewards = () => {
    let json = [{
        title: "Token-Centric Ecosystem",
        description: "The SPHERE token isn't merely a form of digital currency; it's the lifeblood of the Sphere platform. Through the Rewards Protocol, every interaction on the platform, whether it's content creation or digital transactions, is linked with the potential to earn these tokens.",
    },
        // {
        //     title: "Creation Rewards",
        //     description: "Sphere has democratized the process of content creation. Users aren't just contributors; they're stakeholders. Each post, article, or piece of content shared on the platform translates into an earning opportunity, reflecting the value of the contribution to the community.",
        // }, {
        //     title: "Transactional Rewards",
        //     description: "Activities such as trading NFTs or swapping cryptocurrencies on Sphere are further incentivized. The more active a user is in the digital marketplace, the higher the potential reward.",
        // },
        // {
        //     title: "Redemption and Reinvestment",
        //     description: "Accumulated SPHERE tokens can be redeemed for various benefits, or they can be reinvested back into the platform. This two-pronged approach serves to both satisfy users seeking immediate rewards and those looking at long-term platform engagement.",
        // },
        // {
        //     title: "Community-Driven Value",
        //     description: "The Rewards Protocol doesn't just reward individual users; it amplifies the collective value of the SPHERE token. As more users engage, create, and transact, the demand and, consequently, the value of the SPHERE token within the ecosystem can grow, benefiting all stakeholders.",
        // }, {
        //     title: "Adaptive Rewards Scaling",
        //     description: "Recognizing the evolving nature of digital ecosystems, the Rewards Protocol is designed to be adaptive. This means that as the platform grows and changes, the rewards system can adjust accordingly, ensuring continued fair distribution and incentive for all users.",
        // }
    ]

    return (
        <div>
            {json.map((item, index) => (
                <div className="mb-6" key={index}>
                    <p className="text-xl font-semibold text-white">{item.title}</p>
                    <p className="text-lg font-normal text-dark500 mt-1">{item.description}</p>
                </div>
            ))}
        </div>
    )
}