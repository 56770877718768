// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCeAtxPCApxkr2CKdBiIwdtdVBO_9lSs0Q",
    authDomain: "space-1c6f6.firebaseapp.com",
    projectId: "space-1c6f6",
    storageBucket: "space-1c6f6.appspot.com",
    messagingSenderId: "189516133732",
    appId: "1:189516133732:web:a8f94d4a3243516f08b671"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();