

export let colors = {
    main: "#3C5EF9",
    gray500: "#6B7280",
    gray400: "#9CA3AF",
    gray600: "#4B5563",
    red500: "#EF4444",
    gray100: "#F3F4F6",
    gray200: "#E5E7EB",
    gray50: "#F9FAFB",
    neutral900: "#1C1819",
    neutral800: "#292524",
    zinc100: "#F9FAFB",
    zinc200: "#F3F4F6",
    zinc300: "#E5E7EB",
    zinc400: "#D1D5DB",
    zinc500: "#9CA3AF",
    zinc600: "#6B7280",
    zinc700: "#3F3F45",
    zinc800: "#27272A",
    zinc900: "#18181B",
    dark950: "rgb(9 9 11)",
    dark900: "rgb(24 24 27)",
    dark800: "rgb(39 39 42)",
    dark700: "rgb(63 63 70)",
    dark600: "rgb(82 82 91)",
    dark500: "rgb(113 113 122)",
    dark400: "rgb(161 161 170)",
    light50: "rgb(242 242 247)",
    light100: "rgb(229 229 234)",
    light200: "rgb(209 209 214)",
    light300: "rgb(199 199 204)",
    light400: "rgb(174 174 178)",
    light500: "rgb(142 142 147)",
    light600: "rgb(99 99 102)",
    light700: "rgb(72 72 74)",
    light800: "rgb(44 44 46)",
    light900: "rgb(28 28 30)",
}